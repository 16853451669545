const omit = (obj, value) => Object.keys(obj)
  .filter((key) => key !== value)
  .reduce((newObj, key) => ({ ...newObj, [key]: obj[key] }), {});

const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

const getValueInObjByPath = (obj, path) => path.split('.')
  .reduce((currentObj, part) => currentObj[part], obj);

const setWith = (obj, path, value) => {
  const parts = path.split('.');

  const iter = (innerObj, innerPath) => {
    const part = innerPath[0];

    return {
      ...innerObj,
      [part]: innerPath.length === 1 ? value : { ...iter(innerObj[part], innerPath.slice(1)) },
    };
  };

  return iter(obj, parts);
};

const chunk = (arr = [], size = 1) => {
  const arrayOfArrays = [];

  for (let i = 0; i < arr.length; i += size) {
    arrayOfArrays.push(arr.slice(i, i + size));
  }

  return arrayOfArrays;
};

const uuid = () => `f${(~~(Math.random() * 1e8)).toString(16)}`;

const lodashReplaces = {
  omit,
  cloneDeep,
  setWith,
  getValueInObjByPath,
  uuid,
  chunk,
};

export default lodashReplaces;

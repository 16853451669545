import { devFilterMenu } from '../../v2/services/workspace/utils/remapMenu';

import {
  ACCOUNTANTTYPE,
  ALL_TYPES,
  CALCULATOR_TYPE,
  MICETYPE,
  REPORT_TYPES,
  REPORTTYPE,
  SERVICES_TYPES,
  SERVICETYPE,
  VACATION_TYPE,
  RUSHYDROSAP,
  MICEREPORT,
} from '../constants/menu';

const LABELS = { WL_RUSHYDRO: 'WL Русгидро', MICEREPORT: 'Отчет MICE' };

const filterSubmenuItems = (subMenuItems, menuItems, menuType) => menuItems.filter(
  ({ type }) => !subMenuItems.every((report) => type !== menuType[report]),
);

const findIndex = (items, menuType) => items.findIndex(({ type }) => type === menuType);
const changeItems = (items, index, newItem) => [
  ...items.slice(0, index),
  newItem,
  ...items.slice(index),
];

const getMenuItemsFilteredFFArrays = ({
  items, itemsAcc, ffs, menuType, subMenuTypes,
}) => {
  if (!ffs.length) return itemsAcc;

  const menuItem = items.find(({ type }) => type === menuType);
  const hasShowAllReports = ffs.includes(ALL_TYPES);
  const hasWLRushydro = ffs.includes(RUSHYDROSAP);
  const hasMiceReport = ffs.includes(MICEREPORT);

  const indexToInsert = findIndex(items, menuType);

  let menuWithReportItem = filterSubmenuItems(ffs, menuItem.items, subMenuTypes);

  if (hasShowAllReports) {
    menuWithReportItem = [...menuItem.items];
  }

  if (!hasWLRushydro) {
    menuWithReportItem = menuWithReportItem.filter((item) => item.name !== LABELS.WL_RUSHYDRO);
  }

  if (!hasMiceReport) {
    menuWithReportItem = menuWithReportItem.filter((item) => item.name !== LABELS.MICEREPORT);
  }

  const reportItemWithSubmenu = { ...menuItem, items: menuWithReportItem };

  return changeItems(itemsAcc, indexToInsert, reportItemWithSubmenu);
};

const getMenuItemsFilteredFF = ({
  items, itemsAcc, ff, menuType,
}) => {
  if (!ff) return itemsAcc;

  const menuItem = items.find(({ type }) => type === menuType);
  const indexToInsert = findIndex(items, menuType);

  return changeItems(itemsAcc, indexToInsert, menuItem);
};

export const prepareItems = ({
  services = [],
  reports = [],
  items = [],
  mice,
  accountant,
  calculator,
  vacation,
}) => {
  return [
    (i) => i.filter(({ type }) => (
      type !== REPORTTYPE
      && type !== SERVICETYPE
      && type !== ACCOUNTANTTYPE
      && type !== CALCULATOR_TYPE
      && type !== MICETYPE
      && type !== VACATION_TYPE
    )),
    (itemsAcc) => getMenuItemsFilteredFFArrays({
      items, itemsAcc, ffs: reports, menuType: REPORTTYPE, subMenuTypes: REPORT_TYPES,
    }),
    (itemsAcc) => getMenuItemsFilteredFFArrays({
      items, itemsAcc, ffs: services, menuType: SERVICETYPE, subMenuTypes: SERVICES_TYPES,
    }),
    (itemsAcc) => getMenuItemsFilteredFF({
      items, itemsAcc, ff: accountant, menuType: ACCOUNTANTTYPE,
    }),
    (itemsAcc) => getMenuItemsFilteredFF({
      items, itemsAcc, ff: mice, menuType: MICETYPE,
    }),
    (itemsAcc) => getMenuItemsFilteredFF({
      items, itemsAcc, ff: calculator, menuType: CALCULATOR_TYPE,
    }),
    (itemsAcc) => getMenuItemsFilteredFF({
      items, itemsAcc, ff: vacation, menuType: VACATION_TYPE,
    }),
    devFilterMenu,
  ].reduce((prevItems, cb) => cb(prevItems), items);
};

const AMOUNT = 2;
const CHARACTER = '0';

// main function
const lpad = (n, m = AMOUNT, c = CHARACTER) => {
  n = `${n}`;

  m -= n.length;

  while (m-- > 0) n = c + n;

  return n;
};

const rpad = (n, m = AMOUNT, c = CHARACTER) => {
  n = `${n}`;

  m -= n.length;

  while (m-- > 0) n += c;

  return n;
};

export {
  lpad as default,
  rpad,
  lpad,
};
